import {
  createUnifiedTheme,
  genPageTheme,
  palettes,
  shapes
} from '@backstage/theme';

export const dracula = createUnifiedTheme({  
  palette: {  
    ...palettes.dark,  // based on light theme
    primary: {  
      main: '#FFFFFF', // light blue
    },  
    secondary: {  
      main: '#565a6e', // Darker blue grey  
    },

    
    error: {
      main: '#8c4351',
    },
    warning: {
      main: '#8f5e15',
    },
    info: {
      main: '#6272A4',
    },
    success: {
      main: '#6272A4',
    },
    background: {  
      default: '#282A36',
      paper: '#44475A', // Light grey  
    },
    link: '#FFFFFF',
    tabbar: {
      indicator: '#ffffff'
    },
    banner: {
      info: '#6272A4', // light blue banner
      error: '#8c4351',
      text: '#343b58',
      link: '#44475A',
    },

    errorBackground: '#8c4351',
    warningBackground: '#8f5e15',
    infoBackground: '#44475A',    

    navigation: {  
      submenu: {
        background: '#282A36', // light blue
      },
      background: '#44475A', // Lighter grey
      indicator: '#7DF3E1', // lilac as indicator  
      selectedColor: '#7DF3E1', // same as indicator
      color: '#F8F8F2', // dark blue for unselected items  
      navItem: {  
        hoverBackground: '#FF5555', // light blue
      },
    },   
  },
  pageTheme: {
    home: genPageTheme({ colors: ['#282A36', '#FF5555'], shape: shapes.wave }),
    documentation: genPageTheme({
      colors: ['#282A36', '#FF5555'],
      shape: shapes.wave,
    }),
    project: genPageTheme({
      colors: ['#282A36', '#282A36'],
      shape: shapes.wave,
    }),
    tool: genPageTheme({ 
      colors: ['#FF5555', '#FF5555'], 
      shape: shapes.round }),
    library: genPageTheme({
      colors: ['#FF5555', '#FF5555'],
      shape: shapes.round,
    }),
    technique: genPageTheme({ colors: ['#FF5555', '#FF5555'], shape: shapes.round }),
    other: genPageTheme({ colors: ['#282A36', '##FF5555'], shape: shapes.wave }),
    apis: genPageTheme({ colors: ['#282A36', '#FF5555'], shape: shapes.wave }),
  },
  fontFamily: '"DIN OT", Helvetica, Arial, sans-serif',
  components: {
    BackstageInfoCard: {
      styleOverrides: {
        
      }
    },
    BackstageSidebarItem: {
      styleOverrides: {
        root: {
          textDecorationLine: 'none'
        }
      }
    },
    MuiCssBaseline: {
      styleOverrides: `
        @font-face {
          font-family: 'DIN OT';
          src: url('../fonts/DINOT-Light.ttf') format('TrueType');
          font-weight: 300;
          font-style: normal;
        }
        
        @font-face {
          font-family: 'DIN OT';
          src: url('../fonts/DINOT.ttf') format('TrueType');
          font-weight: 400;
          font-style: normal;
        }
        
        @font-face {
          font-family: 'DIN OT';
          src: url('../fonts/DINOT-Medium.ttf') format('TrueType');
          font-weight: 500;
          font-style: normal;
        }
        
        @font-face {
          font-family: 'DIN OT';
          src: url('../fonts/DINOT-Bold.ttf') format('TrueType');
          font-weight: 700;
          font-style: normal;
        }
       `,
    },
    MuiButton: {  
      styleOverrides: { 
        root: {  
          textTransform: 'none', // Remove uppercase text  
        },  
        containedPrimary: {  
          '&:hover': {  
            backgroundColor: '#FF5555', // light blue on hover
          },  
          color: '#111111',  
        },  
        containedSecondary: {  
          '&:hover': {  
            backgroundColor: '#FF5555', // light blue on hover
          },  
          color: '#111111',  
        },
        textPrimary: {
          '&:hover': {  
            backgroundColor: '#FF5555' // light blue on hover
          },
          color: '#ffffff'
        },
        textSecondary: {
          '&:hover': {  
            backgroundColor: '#FF5555' // light blue on hover
          },
          color: '#ffffff'
        },  
      },  
    },
    MuiTableBody: {
      styleOverrides: {
        root: {
          color: '#FFFFFF'
        }
      }
    },
    MuiTypography: {
      styleOverrides: {
        root: {
          color: '#ffffff'
        }
      }
    }
  }
});  