import vpn from "./assets/vpn.png"
import pagerduty from "./assets/pagerduty.png"
import harbor from "./assets/harbor.png"
import snyk from "./assets/snyk.png"
import nr from "./assets/nr.png"
import aws from "./assets/aws.png"
import microsoft from "./assets/microsoftx.png"
import adops from "./assets/adopsx.png"
import scalr from "./assets/scalr.png"

import Typography from "@mui/material/Typography"

import { HomePageSearchBar } from "@backstage/plugin-search"
import {
  HomePageToolkit,
  HomePageCompanyLogo,
  HomePageStarredEntities,
} from '@backstage/plugin-home';


import { Content, Page, InfoCard } from '@backstage/core-components';
import {
  SearchContextProvider,
} from '@backstage/plugin-search-react';
import { Grid, makeStyles, List, ListItem, ListItemIcon, ListItemText } from '@material-ui/core';
import React from 'react';
import LogoFull from "../Root/LogoFull";
import WarningIcon from '@material-ui/icons/Warning';
import PolicyIcon from '@material-ui/icons/Policy';
import BuildIcon from '@material-ui/icons/Build';
import LockResetIcon from '@mui/icons-material/LockReset';
import FavoriteIcon from '@mui/icons-material/Favorite';

const useStyles = makeStyles(theme => ({
  searchBarInput: {
    maxWidth: '60vw',
    margin: 'auto',
    backgroundColor: theme.palette.background.paper,
    borderRadius: '50px',
    boxShadow: theme.shadows[1],
  },
  searchBarOutline: {
    borderStyle: 'none'
  }
}));

const useLogoStyles = makeStyles(theme => ({
  container: {
    margin: theme.spacing(5, 0),
  },
  svg: {
    width: 'auto',
    height: 100,
  },
  path: {
    fill: '#7df3e1',
  },
}));
const style = {
  padding:0,
  color: "#7df3e1"
};

export const HomePage = () => {
  const classes = useStyles();
  const { container } = useLogoStyles();

  return (
    <SearchContextProvider>
      <Page themeId="home">
        <Content>
          <Grid container justifyContent="center" spacing={6}>
            <HomePageCompanyLogo
              className={container}
              logo={<LogoFull/>}     
          />

          <Grid style={style} container item xs={12} justifyContent='center'>
          <Typography variant="h1" gutterBottom >
        Backstage Developer Portal
      </Typography>
          </Grid>
          <Grid container item xs={12} justifyContent='center'>
            <HomePageSearchBar
              InputProps={{ classes: { root: classes.searchBarInput, notchedOutline: classes.searchBarOutline }}}
              placeholder="Search"
            />
          </Grid>
          <Grid container item xs={12}>
            <Grid item xs={12} md={4}>
              <HomePageStarredEntities />
            </Grid>
            <Grid item xs={12} md={4}>
              <InfoCard title="Helpful Documentation">
                <List>
                  <ListItem button component="a" href="catalog/default/Component/developer-portal">
                    <ListItemIcon>
                      <FavoriteIcon />
                    </ListItemIcon>
                    <ListItemText primary="Example Backstage Component" />
                  </ListItem>
                  <ListItem button component="a" href="docs/default/Component/Incident_Management_Docs">
                    <ListItemIcon>
                      <WarningIcon />
                    </ListItemIcon>
                    <ListItemText primary="Incident Management" />
                  </ListItem>
                  <ListItem button component="a" href="docs/default/component/observability_docs">
                    <ListItemIcon>
                      <PolicyIcon />
                    </ListItemIcon>
                    <ListItemText primary="Observability" />
                  </ListItem>
                  <ListItem button component="a" href="https://dev.azure.com/pdd-ihsmarkit/EPD/_git/es-architecture">
                    <ListItemIcon>
                      <BuildIcon />
                    </ListItemIcon>
                    <ListItemText primary="Architecture Guidance" />
                  </ListItem>
                </List>
              </InfoCard>
            </Grid>
            <Grid item xs={12} md={4}>
              <HomePageToolkit
                tools = {
                  [
                    {
                      url: 'https://myapplications.microsoft.com',
                      label: 'MyApps',
                      icon: <img alt='Avatar' style={{ width: 80, height: 80, fontSize: 16 }} src={microsoft} />,
                    },
                    {
                      url: 'https://dev.azure.com/pdd-ihsmarkit/',
                      label: 'ADOPS',
                      icon: <img alt='Avatar' style={{ width: 80, height: 80, fontSize: 16 }} src={adops} />,
                    },
                    {
                      url: 'https://launcher.myapps.microsoft.com/api/signin/2bbbc475-7d7a-4d6d-b410-78c9460414ce?tenantId=0ce83040-50e6-4ae0-bc3c-a2d9046a4a78',
                      label: 'AWS SSO',
                      icon: <img alt='Avatar' style={{ width: 80, height: 80, fontSize: 16 }} src={aws} />,
                    },
                    {
                      url: 'https://launcher.myapps.microsoft.com/api/signin/60eb2998-59eb-4bf2-9ba4-d0cb8188a658?tenantId=0ce83040-50e6-4ae0-bc3c-a2d9046a4a78',
                      label: 'Product VPN',
                      icon: <img alt='Avatar' style={{ width: 80, height: 80, fontSize: 16 }} src={vpn} />,
                    },
                    {
                      url: 'https://launcher.myapps.microsoft.com/api/signin/26c9329b-6a44-44e6-81c0-dc02dbb89c56?tenantId=0ce83040-50e6-4ae0-bc3c-a2d9046a4a78',
                      label: 'New Relic',
                      icon: <img alt='Avatar' style={{ width: 80, height: 80, fontSize: 16 }} src={nr} />,
                    },
                    {
                      url: 'https://launcher.myapps.microsoft.com/api/signin/2353d161-0474-4e89-a833-2c88942d175f?tenantId=0ce83040-50e6-4ae0-bc3c-a2d9046a4a78',
                      label: 'Pagerduty',
                      icon: <img alt='Avatar' style={{ width: 80, height: 80, fontSize: 16 }} src={pagerduty} />,
                    },
                    {
                      url: 'https://launcher.myapps.microsoft.com/api/signin/6ac4e083-1968-4443-92aa-36ad24b82ab0?tenantId=0ce83040-50e6-4ae0-bc3c-a2d9046a4a78',
                      label: 'Harbor',
                      icon: <img alt='Avatar' style={{ width: 80, height: 80, fontSize: 16 }} src={harbor} />,
                    },
                    {
                      url: 'https://launcher.myapps.microsoft.com/api/signin/64fc7106-7fc5-4ea0-b747-34a344b84ff4?tenantId=0ce83040-50e6-4ae0-bc3c-a2d9046a4a78',
                      label: 'Snyk',
                      icon: <img alt='Avatar' style={{ width: 80, height: 80, fontSize: 16 }} src={snyk} />,
                    },
                    {
                        url: 'https://launcher.myapps.microsoft.com/api/signin/4135119f-e3ab-4440-b6cc-1603a5b0fda3?tenantId=0ce83040-50e6-4ae0-bc3c-a2d9046a4a78',
                        label: 'Scalr',
                        icon: <img alt='Avatar' style={{ width: 80, height: 80, fontSize: 16,  }} src={scalr} />,
                    },
                    {
                        url: 'https://legacysspr.accuris.dev/',
                        label: 'IHS PW Reset',
                        icon: <LockResetIcon style={{ width: 83, height: 83, fontSize: 16}}></LockResetIcon>,
                    },                    
                  ]
                }
              />
            </Grid>
            <Grid>
            </Grid>
          </Grid>
          </Grid>
        </Content>
      </Page>
    </SearchContextProvider>
  );
};