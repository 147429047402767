import {
    CatalogKindExploreContent,
    ExploreLayout,
  } from '@backstage-community/plugin-explore';
import React from 'react';

  
  export const ExplorePage = () => {
    return (
      <ExploreLayout
        title="Accuris"
      >
        <ExploreLayout.Route path="products" title="Products">
          <CatalogKindExploreContent kind="domain" title="Accuris" />
        </ExploreLayout.Route>
      </ExploreLayout>
    );
  };
  
  export const explorePage = <ExplorePage />;