import React from 'react';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles({
  svg: {
    width: 'auto',
    height: 33,
    display: 'flex',
    alignItems: 'center',
  },
  path: {
    fill: '#7df3e1',
  },
});
const LogoFull = () => {
  const classes = useStyles();

  return (
<svg
  className={classes.svg}
  version="1.1"
  viewBox="0 0 120.15 64.179"
  xmlns="http://www.w3.org/2000/svg"
  >
  <g
    transform="translate(-44.797 -32.265)"
    fill="#1000e8"
  >
    <path className={classes.path} transform="translate(0 -4.4456)" d="m104.35 70.494-11.884 3.1831c-.42345.11342-1.018.45666-1.328.76663l-8.6118 8.6118c-.30998.30998-.65324.90453-.76669 1.328l-3.1517 11.763c-.11345.42344-.20542.76694-.20542.76722h4.3822c.43838 0 .79375-.000231.79375-.000517l2.6637-9.9422c.11345-.42344.4567-1.018.76668-1.328l6.7173-6.7173c.30998-.30998.90453-.65323 1.328-.76668l9.1755-2.4582c.42344-.11345 1.11-.11345 1.5334-9e-6l9.176 2.4583c.42344.11344 1.018.45669 1.328.76667l6.7173 6.7173c.30998.30998.65324.90453.76668 1.328l2.4582 9.1755c.11344.42344.20541.76694.20541.76723h4.3816c.43838 0 .79375-.000231.79375-.000517l-3.3567-12.53c-.11343-.42344-.45668-1.018-.76667-1.328l-8.6122-8.6118c-.30999-.30997-.90455-.65322-1.328-.76667l-11.763-3.1517c-.42344-.11345-1.0561-.12745-1.4132-.03127z" color="#000000"/>
    <path className={classes.path} d="m105.03 43.632-23.172 5.8638c-.42498.10754-1.0243.44247-1.3386.74808l-17.049 16.579c-.31428.30561-.66581.89531-.78516 1.3171l-6.4738 22.881c-.11935.42182-.13859 1.0699-.04298 1.4474h4.3641c.43838 0 .72434-.27393.63872-.61185l5.9613-21.066c.11936-.42181.4709-1.0115.78517-1.3171l15.126-14.71c.31427-.30562.91355-.64058 1.3385-.74814l20.455-5.1771c.42498-.10756 1.1114-.09799 1.5332.02137l20.304 5.7457c.42181.11936 1.0115.47092 1.3171.78523l14.708 15.127c.30559.31431.64052.91361.74808 1.3386l5.022 19.842c.10756.42498.55013.76949.98851.76949h3.5698c.43838 0 .70657-.34452.59904-.7695l-5.6598-22.368c-.10753-.42498-.44244-1.0243-.74804-1.3386l-16.577-17.049c-.3056-.3143-.89528-.66584-1.3171-.78518l-22.882-6.4739c-.42182-.11934-1.0541-.14274-1.4123-.05227z" color="#000000"/>
    <path className={classes.path} d="m104.7 54.84-17.529 4.5243c-.42446.10956-1.0221.44735-1.335.75447l-12.828 12.595c-.31281.30712-.66154.8985-.7789 1.3209l-4.8134 17.322c-.11737.42237-.17226.92048-.12259 1.1126h4.3698c.43838 0 .75905-.13488.71624-.30127l4.3091-15.505c.11739-.42237.46613-1.0137.77893-1.3209l10.916-10.718c.3128-.30713.91047-.64493 1.3349-.7545l14.812-3.8236c.42447-.10957 1.111-.10325 1.5333.01411l14.742 4.0962c.42238.11736 1.0138.46608 1.3209.77888l10.718 10.916c.30712.3128.64492.91048.75449 1.3349l3.7466 14.514c.10957.42446.55376.76856.99214.76856h3.576c.43838 0 .70495-.3441.59542-.76857l-4.4025-17.061c-.10953-.42447-.44732-1.0221-.75446-1.3349l-12.596-12.828c-.30714-.31279-.89853-.6615-1.3209-.77887l-17.322-4.8134c-.42237-.11737-1.0551-.13732-1.4133-.04456z" color="#000000"/>
    <g transform="matrix(1.25 0 0 1.25 90 72.595)">
      <path className={classes.path} d="m12 6c-5 0-11 6-11 6s6 6 11 6 11-6 11-6-6-6-11-6zm0 10c-2.2 0-4-1.8-4-4s1.8-4 4-4 4 1.8 4 4-1.8 4-4 4z"/>
      <circle className={classes.path} cx="12" cy="12" r="2"/>
    </g>
  </g>
</svg>
  );
};

export default LogoFull;